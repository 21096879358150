<template>
  <div class="d-flex flex-column">
    <div
      v-if="label"
      class="d-flex mb-3 black--text"
    >
      {{ label }}
    </div>
    <div class="d-flex">
      <VAvatar
        size="96"
        color="grey lighten-4"
        class="company-avatar mr-6"
        @click="uploadAvatar"
      >
        <VImg
          :src="picBase64"
        >
          <template #placeholder>
            <VRow
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <VProgressCircular
                v-if="uploading"
                indeterminate
                color="grey lighten-1"
              />
              <VIcon
                v-else
                color="grey darken-2"
              >
                fal fa-image
              </VIcon>
            </VRow>
          </template>
        </VImg>
      </VAvatar>
      <template v-if="isUpload">
        <div>
          Вы можете загрузить изображение<br>в формате JPG или PNG<br>
          <a @click="uploadAvatar">Выбрать файл</a>
        </div>
        <VFileInput
          id="avatar"
          ref="avatar"
          v-model="file"
          type="file"
          accept="image/x-png,image/jpeg"
          class="d-none"
        />
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompanyAvatar',
  props: {
    url: { type: String, default: null },
    label: { type: String, default: null },
    isUpload: { type: Boolean, default: false },
  },
  data() {
    return {
      uploading: false,
      file: null,
      errors: [],
      picBase64: null,
    };
  },
  watch: {
    async file() {
      this.picBase64 = null;
      if (this.file) {
        this.uploading = true;
        this.picBase64 = await this.fileToBase64();
        this.uploading = false;
        this.$emit('update', this.file);
      }
    },
    url(v) {
      this.picBase64 = v;
    },
  },
  created() {
    this.picBase64 = this.url;
  },
  methods: {
    uploadAvatar() {
      if (this.isUpload) { this.$refs.avatar.$refs.input.click(); }
    },
    fileToBase64() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};

</script>

<style scoped>
.company-avatar{
  border: 1px solid #EBECED;
}
</style>
