import { render, staticRenderFns } from "./CompanyAvatar.vue?vue&type=template&id=1d5df0e0&scoped=true&"
import script from "./CompanyAvatar.vue?vue&type=script&lang=js&"
export * from "./CompanyAvatar.vue?vue&type=script&lang=js&"
import style0 from "./CompanyAvatar.vue?vue&type=style&index=0&id=1d5df0e0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d5df0e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VFileInput,VIcon,VImg,VProgressCircular,VRow})
